import React from "react";
import { Container, Row , Col } from "reactstrap";
import "./Footer.scss"

//Imgs//
import carbon from "../../Assets/Img/carbon.png"

//Icons//
import facebook from "../../Assets/Img/facebook.png"
import instagram from "../../Assets/Img/instagram.png"
import twitter from "../../Assets/Img/twitter.png"
import dribble from "../../Assets/Img/dribble.png"
import linkedin from "../../Assets/Img/linkedin.png"

const Footer = () => {
    return(
        <section id="Footer">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="footer-container d-flex">
                            <div className="d-flex footer-content">
                                <div className="d-flex flex-column ms-3 me-5 content">
                                    <h6>Lincoln</h6>
                                    <a href="google.com">
                                        <div>01522 528246</div>
                                        <div>design@rootstudio.co.uk</div>
                                        <div>The Terrace, Grantham</div>
                                        <div>Street, Lincoln, LN2 1BD</div>
                                    </a>
                                </div>

                                <div className="d-flex flex-column ms-5 content2">
                                    <h6>Leicester</h6>
                                    <a href="google.com">
                                        <div>0116 253 3454</div>
                                        <div>design@rootstudio.co.uk</div>
                                        <div>LCB Depot, 31 Rutland St,</div>
                                        <div>Leicester LE1 1RE</div>
                                    </a>
                                </div>
                            </div>

                            <div className="social-container d-flex">
                                <div className="mx-4">
                                    <h6 className="d-flex justify-content-center">Policies</h6>
                                    <a href="https://rootstudio.co.uk/privacy-policy">
                                        <div>Privacy policy</div>
                                    </a>
                                </div>

                                <div className="img-container mx-4">
                                    <img src={carbon} alt="carbonxd" />
                                </div>

                                <div className="social-media ms-4">
                                    <h6>Follow us</h6>
                                    <a className="me-3" href="facebook.com">
                                        <img src={facebook} alt="face" />
                                    </a>
                                    <a className="me-3" href="instagram.com">
                                        <img src={instagram} alt="insta" />
                                    </a>
                                    <a className="me-3" href="twitter.com">
                                        <img src={twitter} alt="twitter" />
                                    </a>
                                    <a className="me-3" href="dribble.com">
                                        <img src={dribble} alt="dribble" />
                                    </a>
                                    <a href="linkedin.com">
                                        <img src={linkedin} alt="link" />
                                    </a>
                                    <h6 className="mt-4 root-studio">Root Studio</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Footer;