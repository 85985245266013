import React from "react";
import { Container, Row , Col } from "reactstrap";
import "./MissionBurrito.scss";
import Burrito from "../../Assets/Img/MissionBurrito.gif"

const MissionBurrito = () => {

    return (

        <section id="MissionBurrito">
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center">
                    <img src={Burrito} alt="Mission Burrito" />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">   
                        <div className="reverseSquare d-flex justify-content-center">
                            <div className="textBurrito  position-relative">
                                <p>
                                    The team listen and respond positively to feedback when tackling our challenging requests. If you are looking for designers that provide a professional and collaborative approach then we can't recommend Root Studio enough.                                    
                                </p> 
                                <a href="https://rootstudio.co.uk/work/12-mission-burrito" className=" link-button position-absolute">
                                    <button className="buttonCase">See case study</button>
                                </a>
                            </div>
                        </div>               
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default MissionBurrito