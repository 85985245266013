import Navbar from "./Pages/Index/Components/Navbar/Navbar";
import Homebanner from "./Pages/Index/Components/Home-banner/Home-banner";
import HappyFri from "./Pages/Index/Components/HappyFry/HappyFry";
import Collagefoto from "./Pages/Index/Components/Collage/Collage";
import MissionBurrito from "./Pages/Index/Components/MissionBurrito/MissionBurrito";
import Proyects from "./Pages/Index/Components/Proyects/Proyects";
import Footer from "./Pages/Index/Components/Footer/Footer";
import "./Styles/index.scss"

function App() {

  return (
    <>  
      <Navbar/>
      <Homebanner/>
      <HappyFri/>
      <Collagefoto/>
      <MissionBurrito/>
      <Proyects/>
      <Footer/>
    </>
  );
}
export default App;
