import React, {useState} from "react";
import { Container, Row, Col} from "reactstrap";
import './Navbar.scss';

import icon from "../../Assets/Img/list.svg"

const Navbar = () => {

const [list, setlist] = useState(false)

    return (
        <section id="navbar">
          <Container>
            <Row className="d-flex align-items-center justify-content-center">
              <Col xs="9" lg="5">
                <div className="d-flex justify-content-center">
                  <a className="logo" href="#">
                  <div>ROOT<span>STUDIO</span></div>
                  </a>
                </div>
              </Col>

              <Col xs="3" lg="5" className="burguer">
                <div className="icon-container">
                  <button onClick={()=> setlist(!list)}>
                    <img className="icon" src={icon} alt="icono"/>
                  </button>
                </div>
              </Col>

              <Col lg="7">
                <nav className="nav-list d-flex align-items-center justify-content-start">
                  <ul className={`hoverli d-flex justify-content-center ${ list ? "column" : ""}`} >
                  { list ? 

                  <div className="icon-container inside">
                  <button onClick={()=> setlist(!list)}>
                    <img className="icon" src={icon} alt="icono"/>
                  </button>
                 </div> : <></>}

                  <div className="d-flex justify-content-center">
                    <a className="logo" href="#">
                    <div>ROOT<span>STUDIO</span></div>
                    </a>
                  </div>

                    <li className="me-2"><a href="#">Studio</a></li>
                    <li className="me-2"><a href="#">Work</a></li>
                    <li className="me-2"><a href="#">Notes</a></li>
                    <li className="me-2 selected"><a href="#">Contact</a></li>
                    <li><a href="#">Join us</a></li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
      </section>
    )

}

export default Navbar