import React from "react";
import { Container, Row , Col } from "reactstrap";
import "./Collage.scss";

//Imgs//
import transmission from "../../Assets/Img/transmission.jpeg";
import welcome from "../../Assets/Img/welcome.jpeg";
import oldkings from "../../Assets/Img/oldkings.jpeg";
import aura from "../../Assets/Img/aura.jpeg";


const Collagefoto = () => {
    return(
        <section id="Collage">
            <Container>
                <Row>
                    <Col xs="12" lg="8">
                        <a className="article-url" href="https://rootstudio.co.uk/work/25-transmission-private">
                            <div className="article-container position-relative mb-4" style={{backgroundImage: `url(${transmission})`}}>
                                <div className="article-content right">
                                    <h5>Transmission Private, London</h5> <br />
                                    <p>Print and digital marketing support for london's innovate marketing agency, Transmission private →</p>    
                                </div>
                            </div>                  
                        </a>
                    </Col>
                    <Col xs="12" lg="4">
                        <a className="article-url" href="https://rootstudio.co.uk/work/25-transmission-private">
                            <div className="article-container position-relative mb-4" style={{backgroundImage: `url(${welcome})`}}>
                                <div className="article-content top">
                                    <h5>Lincoln Students Union</h5>
                                    <br />
                                    <p>How we rebranded the official Lincoln Freshers Week with a distinctive and unmistakable personality →</p>    
                                </div>
                            </div> 
                        </a>
                    </Col>
                    <Col xs="12" lg="4">
                        <a className="article-url" href="https://rootstudio.co.uk/work/25-transmission-private">
                            <div className="article-container position-relative mb-4" style={{backgroundImage: `url(${oldkings})`}}>
                                <div className="article-content bottom">
                                    <h5>The Old King Head, Boston</h5>
                                    <br />
                                    <p>A creative brandfor a unique and historic cafe and B&B near Boston →</p>    
                                </div>
                            </div> 
                        </a>
                    </Col>
                    <Col xs="12" lg="8" >
                        <a className="article-url" href="https://rootstudio.co.uk/work/25-transmission-private">
                            <div className="article-container position-relative mb-4" style={{backgroundImage: `url(${aura})`}}>
                                <div className="article-content right">
                                    <h5>Aura Hair & Beauty Academy</h5>
                                    <br />
                                    <p>1920s inspired brand design for Aura hair & beauty academy →</p>    
                                </div>
                            </div> 
                        </a>
                    </Col>
                </Row>

                <Row>
                    <Col className="lineBack d-flex justify-content-center align-items-center position-relative">
                    <a className="astyle" href="https://rootstudio.co.uk/work/37-aura-hair-salon-lincoln">
                        <button className="buttonshow">Show me everything</button>
                    </a>
                    </Col>
                </Row>

            </Container>
        </section>
    )
}

export default Collagefoto;