import React from "react";
import { Container} from "reactstrap";
import "./Homebanner.scss";

//Imgs//
import jirafa from "../../Assets/Img/Jirafa.png"

const Homebanner = () => {

    return(
        <section id="Homebanner">
                <a className="Colbanner d-flex justify-content-center" href="https://rootstudio.co.uk/work/8-chester-zoo">
            <Container>
                <div className="wrapper position-relative">
                    <div className="content-root d-flex align-items-center justify-content-center text-center">
                        <div className="container-content d-flex flex-column">
                            <span className="Bestideas">THE BEST IDEAS TAKE</span> 
                            <span className="Rootwhite">ROOT</span>
                        </div>
                    </div> 

                <div className="container-jirafa d-flex justify-content-end align-items-end">
                    <div className="jirafa">
                        <img className="img-jirafa" src={jirafa} alt="jiraffe" />
                    </div>
                </div>

                
                    <div className="content-cta d-flex justify-content-center align-items-end">
                        <div className="Colchester d-flex align-items-center flex-column">
                            <div className="Seework">See our work for</div>
                            <div className="Chester">Chester Zoo</div>
                        </div>         
                    </div>
                </div>
            </Container>
                </a>
        </section>
    )
}

export default Homebanner


