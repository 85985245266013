import React from "react";
import { Container, Row , Col } from "reactstrap";
import "./Proyects.scss"

//Imgs//
import proyect1 from "../../Assets/Img/proyect1.jpeg";
import proyect2 from "../../Assets/Img/proyect2.jpeg";
import proyect3 from "../../Assets/Img/proyect3.jpeg";

const Proyects = () => {
    return(
        <section id="Proyects">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center" xs="10" sm="9" md="8 mb-4" lg="5" xl="4">
                    <a className="proyect" href="https://rootstudio.co.uk/notes/post/144-setting-a-benchmark-with-the-new-wilkin-chapman-solicitors-website">
                        <div>
                            <div className="proyectImg" style={{backgroundImage: `url(${proyect1})`}}></div>
                                <div className="proyect-content">
                                    <h6>Proyect News</h6>
                                <div>Last Spring we embarked on an ambitious journey with Wilkin Chapman’s marketing team to not only create a fresh look for their website but to push the industry benchmark to new heights both visually and technically. </div>
                            </div>
                        </div>
                    </a>
                    </Col>

                    <Col className="d-flex justify-content-center" xs="10" sm="9" md="8 mb-4" lg="5" xl="4">
                        <a className="proyect" href="https://rootstudio.co.uk/notes/post/144-setting-a-benchmark-with-the-new-wilkin-chapman-solicitors-website">
                            <div>
                                <div className="proyectImg" style={{backgroundImage: `url(${proyect2})`}}></div>
                                    <div className="proyect-content">
                                        <h6>Proyect News</h6>
                                    <div>A section-by-section website redesign for privacy-focussed Crypto Tax SAAS company, Recap </div>
                                </div>
                            </div>
                        </a>
                    </Col>  

                    <Col className="d-flex justify-content-center" xs="10" sm="9" md="8" lg="5" xl="4">
                        <a className="proyect" href="https://rootstudio.co.uk/notes/post/143-saas-website-design-for-crypto-tax-experts-recap">
                            <div>
                                <div className="proyectImg" style={{backgroundImage: `url(${proyect3})`}}></div>
                                    <div className="proyect-content">
                                        <h6>Proyect News</h6>
                                    <div>A fresh, modern website for Hub Rural with a stronger, more usable and SEO friendly, approach to content and structure. </div>
                                </div>
                            </div>
                        </a>
                    </Col>  
                </Row>
            </Container>
        </section>
    )
};

export default Proyects;