import React from "react";
import { Container, Row , Col } from "reactstrap";
import "./HappyFry.scss";

const HappyFri = () => {
    return(
        <section id="HappyFry">
            <Container>

                <Row className="TextHappy">
                        <Col xs="12" xl="5">
                            <div className="title_">
                                <span className="Happy">Happy Fri-yay!</span> <br />
                                <span className="Makeyour">make yourself at home</span>
                            </div>
                        </Col>

                        <Col xs="12" xl="5">
                        </Col>

                        <Col lg="5" md="8">
                            <div className="d-flex align-items-end">
                                <div className="Big-font">We’re a graphic and digital design agency working with clients worldwide to deliver unforgettable projects. Working together we help established brands and ambitious start-ups achieve their full potential.</div>
                            </div>
                        </Col>    

                        <Col lg="5" md="8">
                            <div className="d-flex align-items-end">
                                <div className="Little-font">Our award winning team have brought great design and creative strategy to authentic brands for over 13 years. From branding, print, web design, digital marketing and illustration; our diverse range of skills allow us to focus on the why not just the how, allowing for more effective experiences of your brand for your customers.</div>
                            </div>
                        </Col>
                </Row>

            </Container>
        </section>
            )
}

export default HappyFri;